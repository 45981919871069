var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center text-2xl mb-6"},[_c('span',{staticClass:"headline"},[_vm._v("Organisations")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary"},on:{"click":_vm.openCreateModal}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',{staticClass:"ml-2"},[_vm._v("Create")])],1)],1),_c('CompanySearchBox',{attrs:{"value":_vm.routeQueryFilter},on:{"submit":_vm.onSearchSubmit}}),(_vm.loading)?_c('div',[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-progress-circular',{staticClass:"mx-auto flex",attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1)],1):_c('v-card',[_c('DataTableHeader',{attrs:{"table-id":"organisation-table table","data":_vm.companyList,"headers":_vm.headers,"show-export":""},on:{"update:search":function($event){_vm.searchText = $event}}}),_c('v-data-table',{attrs:{"data-has-actions":"true","id":"organisation-table","fixed-header":"","headers":_vm.$filterHeaders(_vm.headers),"options":_vm.companiesTableOptions,"items":_vm.companyList,"sort-by":"id","search":_vm.searchText},on:{"update:options":function($event){_vm.companiesTableOptions=$event},"click:row":_vm.viewItem},scopedSlots:_vm._u([{key:"item.outcomesText",fn:function(ref){
var item = ref.item;
return _vm._l((item.outcomesText),function(outcome){return _c('v-chip',{key:outcome,attrs:{"small":""}},[_vm._v(" "+_vm._s(outcome)+" ")])})}},{key:"item.challengesText",fn:function(ref){
var item = ref.item;
return _vm._l((item.challengesText),function(challenge){return _c('v-chip',{key:challenge,attrs:{"small":""}},[_vm._v(" "+_vm._s(challenge)+" ")])})}},{key:"item.techAreasText",fn:function(ref){
var item = ref.item;
return _vm._l((item.techAreasText),function(techArea){return _c('v-chip',{key:techArea,attrs:{"small":""}},[_vm._v(" "+_vm._s(techArea)+" ")])})}},{key:"item.industriesText",fn:function(ref){
var item = ref.item;
return _vm._l((item.industriesText),function(industry){return _c('v-chip',{key:industry,attrs:{"small":""}},[_vm._v(" "+_vm._s(industry)+" ")])})}},{key:"item.tagsText",fn:function(ref){
var item = ref.item;
return _vm._l((item.tagsText),function(tag){return _c('v-chip',{key:tag,attrs:{"small":""}},[_vm._v(" "+_vm._s(tag)+" ")])})}},{key:"item.raisingNow",fn:function(ref){
var item = ref.item;
return [(item.raisingNow)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle")])]}},{key:"item.website",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.website,"target":"_blank"}},[_vm._v(_vm._s(item.website))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"gray"},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.actions2",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-auto",attrs:{"icon":"","color":"gray"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }