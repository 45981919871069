<template>
  <div>
    <div class="d-flex align-center text-2xl mb-6">
      <span class="headline">Organisations</span>
      <v-spacer></v-spacer>
      <v-btn class="mt-3" color="primary" @click="openCreateModal">
        <v-icon>mdi-plus</v-icon>
        <span class="ml-2">Create</span>
      </v-btn>
    </div>
    <CompanySearchBox :value="routeQueryFilter" @submit="onSearchSubmit" />
    <div v-if="loading">
      <v-card>
        <v-card-text>
          <v-container>
            <v-progress-circular class="mx-auto flex" indeterminate color="primary"></v-progress-circular>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    <v-card v-else>
      <DataTableHeader table-id="organisation-table table" :data="companyList" :headers="headers" show-export @update:search="searchText = $event" />
      <v-data-table
        data-has-actions="true"
        id="organisation-table"
        fixed-header
        :headers="$filterHeaders(headers)"
        :options.sync="companiesTableOptions"
        :items="companyList"
        sort-by="id"
        :search="searchText"
        @click:row="viewItem"
      >
        <template v-slot:item.outcomesText="{ item }">
          <v-chip v-for="outcome in item.outcomesText" :key="outcome" small>
            {{ outcome }}
          </v-chip>
        </template>
        <template v-slot:item.challengesText="{ item }">
          <v-chip v-for="challenge in item.challengesText" :key="challenge" small>
            {{ challenge }}
          </v-chip>
        </template>
        <template v-slot:item.techAreasText="{ item }">
          <v-chip v-for="techArea in item.techAreasText" :key="techArea" small>
            {{ techArea }}
          </v-chip>
        </template>
        <template v-slot:item.industriesText="{ item }">
          <v-chip v-for="industry in item.industriesText" :key="industry" small>
            {{ industry }}
          </v-chip>
        </template>
        <template v-slot:item.tagsText="{ item }">
          <v-chip v-for="tag in item.tagsText" :key="tag" small>
            {{ tag }}
          </v-chip>
        </template>
        <template v-slot:item.raisingNow="{ item }">
          <v-icon v-if="item.raisingNow" color="green">mdi-check-circle</v-icon>
          <v-icon v-else color="red">mdi-close-circle</v-icon>
        </template>
        <template v-slot:item.website="{ item }">
          <a :href="item.website" target="_blank">{{ item.website }}</a>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon color="gray" @click.stop="editItem(item)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.actions2="{ item }">
          <v-btn icon color="gray" class="mx-auto" @click.stop="deleteItem(item)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CreateCompanyModal from '@/components/company/CreateCompanyModal';
import DataTableHeader from '@/components/ui/DataTableHeader';
import DataTableHeaderMixin from '@/mixins/DataTableHeader';
import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT } from '@/helpers/constants';
import CompanySearchBox from '@/components/company/CompanySearchBox';
import { stringify } from '@/helpers/api';
import ViewCompanyModal from '@/components/company/ViewCompanyModal';

export default {
  name: 'CompanyList',
  components: { CompanySearchBox, DataTableHeader },
  data: () => ({
    headers: [
      { text: 'Edit', value: 'actions', sortable: false, width: '30px', align: 'center' },
      { text: 'Company Name', value: 'name' },
      { text: 'City', value: 'location', hideable: true, show: true },
      { text: 'Country', value: 'country.name', hideable: true, show: false },
      { text: 'Region', value: 'region', hideable: true, show: false },
      { text: 'Type', value: 'companyTypeText', hideable: true, show: true },
      { text: 'Inn. Areas', value: 'techAreasText', hideable: true, show: false },
      { text: 'Description', value: 'description', hideable: true, show: false },
      { text: 'SC Insights', value: 'SCInsights', hideable: true, show: false },
      { text: 'Outcomes', value: 'outcomesText', hideable: true, show: false },
      { text: 'Challenges', value: 'challengesText', hideable: true, show: false },
      { text: 'Industries', value: 'industriesText', hideable: true, show: false },
      { text: 'Tags', value: 'tagsText', hideable: true, show: false },
      { text: 'Website', value: 'website', hideable: true, show: false },
      { text: 'Geography of Focus', value: 'geographyOfFocus', hideable: true, show: false },
      { text: 'Raising Now', value: 'raisingNow', hideable: true, show: false },
      { text: 'Updated Date', value: 'updatedAt', hideable: true, show: false },
      { text: 'Actions', value: 'actions2', sortable: false, width: '30px', align: 'center' },
    ],
    searchText: '',
    companiesTableOptions: {},
    loading: false,
  }),
  computed: {
    ...mapState(['companies']),
    companyList() {
      return this.companies.map((c) => {
        let companyTypeText = c.companyType.name;
        if (c.companyStage) {
          companyTypeText = `${companyTypeText} - ${c.companyStage.name}`;
        }
        return {
          ...c,
          companyTypeText,
          outcomesText: c.outcomes.map((o) => o.name),
          challengesText: c.challenges.map((o) => o.name),
          techAreasText: c.techAreas.map((o) => o.name),
          industriesText: c.industries.map((o) => o.name),
          tagsText: c.tags.map((o) => o.name),
          updatedAt: dayjs(c.updated_at).format(DEFAULT_DATE_FORMAT),
        };
      });
    },
    routeQueryFilter() {
      return this.$route.query?.filters ? JSON.parse(this.$route.query.filters) : {};
    },
  },
  created() {
    this.fetchCompaniesData(this.routeQueryFilter);
  },
  methods: {
    ...mapActions(['fetchCompanies', 'fetchCompanyTypes', 'deleteCompany']),
    openCreateModal() {
      this.$showModal(CreateCompanyModal);
    },
    viewItem(item) {
      this.$showModal(ViewCompanyModal, { id: item.id });
    },
    editItem(item) {
      this.$showModal(CreateCompanyModal, { id: item.id });
    },
    deleteItem(item) {
      const modal = this.$showConfirmModal(null, {
        confirm: () => {
          this.deleteCompany(item.id);
          modal.close();
        },
      });
    },
    onSearchSubmit(filterData) {
      if (filterData) {
        if (this.$route.query.filters !== stringify(filterData)) {
          this.$router.replace({
            path: this.$route.path,
            query: {
              filters: stringify(filterData),
            },
          });
        }
      }
      this.fetchCompaniesData(filterData);
    },
    async fetchCompaniesData(filters) {
      this.loading = true;
      // { challenge: this.$route.query?.challenge, outcome: this.$route.query?.outcome, techArea: this.$route.query?.techArea, stage: this.$route.query?.stage }
      await this.fetchCompanies({ filters, pageSize: 10, page: 1 });
      this.loading = false;
    },
  },
  mixins: [DataTableHeaderMixin],
};
</script>
<style lang="scss">
#organisation-table {
  td:not(:first-child):not(:last-child) {
    cursor: pointer;
  }
}
</style>
