<template>
  <v-card class="mb-1">
    <v-expansion-panels v-model="open" tile flat accordion>
      <v-expansion-panel>
        <v-expansion-panel-header class="font-weight-medium text-gray-800">Multi-Match</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="d-flex text-gray-800">
            <v-col v-for="(param, index) in searchParameters" :key="index" cols="12" sm="6" md="4" class="pb-0">
              <div class="ll font-weight-medium text-body-2 pt-1" :data-ll="param.label"></div>
              <div>
                <template v-if="param.type === 'text'">
                  <v-text-field class="text-body-2 search-input" v-model="searchParameterValues[param.name]" single-line hide-details dense @keyup.enter="apply"></v-text-field>
                </template>
                <template v-else-if="param.type === 'checkbox'">
                  <v-checkbox class="" v-model="searchParameterValues[param.name]" single-line hide-details dense></v-checkbox>
                </template>
                <template v-else-if="param.type === 'dropdown'">
                  <autocomplete
                    class="text-body-2 search-input"
                    v-model="searchParameterValues[param.name]"
                    :items="param.items"
                    item-value="value"
                    item-text="label"
                    hide-details
                    dense
                    clearable
                  ></autocomplete>
                </template>
                <template v-else-if="param.type === 'dropdown-multiselect'">
                  <autocomplete
                    class="text-body-2 search-input"
                    v-model="searchParameterValues[param.name]"
                    :items="param.items"
                    item-value="value"
                    item-text="label"
                    hide-details
                    dense
                    clearable
                    multiple
                  ></autocomplete>
                </template>
              </div>
            </v-col>
          </v-row>
          <div class="mt-5">
            <v-btn color="primary" small @click="apply">Apply</v-btn>
            <v-btn class="ml-3" small @click="clear">Clear</v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'CompanySearchBox',
  props: {
    value: Object,
  },
  data() {
    return {
      searchTextLocal: '',
      searchParameterValues: {
        name: null,
        companyType: null,
        location: null,
        region: null,
        challenge: null,
        outcome: null,
        techArea: null,
        tag: null,
        geographyOfFocus: null,
        raisingNow: null,
      },
      open: null,
    };
  },
  created() {
    if (this.value && typeof this.value === 'object' && Object.keys(this.value)?.length > 0) {
      this.open = 0;
      Object.keys(this.value).forEach((itemName) => {
        const searchItem = this.searchParameters.find((p) => p.name === itemName);
        if (searchItem) {
          this.searchParameterValues[itemName] = this.value[itemName];
        }
      });
    }
  },
  computed: {
    ...mapState(['companyTypes', 'challenges', 'outcomes', 'techAreas', 'industries', 'regions', 'tags']),
    ...mapGetters(['getCountriesArray']),
    searchParameters() {
      return [
        {
          name: 'name',
          label: 'Name',
          type: 'text',
        },
        {
          name: 'companyType',
          label: 'Type',
          type: 'dropdown',
          items: this.companyTypes.map((c) => {
            return {
              label: c.name,
              value: c.id.toString(),
            };
          }),
        },
        {
          name: 'industry',
          label: 'Industry',
          type: 'dropdown-multiselect',
          items: this.industries.map((c) => {
            return {
              label: c.name,
              value: c.id.toString(),
            };
          }),
        },
        {
          name: 'location',
          label: 'City',
          type: 'text',
        },
        {
          name: 'techArea',
          label: 'Inn. Area',
          type: 'dropdown-multiselect',
          items: this.techAreas.map((c) => {
            return {
              label: c.name,
              value: c.id.toString(),
            };
          }),
        },
        {
          name: 'geographyOfFocus',
          label: 'Geography of Focus',
          type: 'text',
        },
        {
          name: 'country',
          label: 'Country',
          type: 'dropdown-multiselect',
          items: this.getCountriesArray.map((c) => {
            return {
              label: c.name,
              value: c.code,
            };
          }),
        },
        {
          name: 'outcome',
          label: 'Outcome',
          type: 'dropdown-multiselect',
          items: this.outcomes.map((c) => {
            return {
              label: c.name,
              value: c.id.toString(),
            };
          }),
        },
        {
          name: 'raisingNow',
          label: 'Raising Now',
          type: 'dropdown',
          items: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          value: null,
        },
        {
          name: 'region',
          label: 'Region',
          type: 'dropdown-multiselect',
          items: this.regions,
        },
        {
          name: 'challenge',
          label: 'Challenge',
          type: 'dropdown-multiselect',
          items: this.challenges.map((c) => {
            return {
              label: c.name,
              value: c.id.toString(),
            };
          }),
        },
        {
          name: 'tag',
          label: 'Tag',
          type: 'dropdown-multiselect',
          items: this.tags.map((c) => {
            return {
              label: c.name,
              value: c.id.toString(),
            };
          }),
        },
      ];
    },
  },
  methods: {
    ...mapMutations(['SET_SEARCH_FILTER_DATA']),
    filterData() {
      const queryString = {};
      const filledData = this.searchParameters.filter((i) => this.searchParameterValues[i.name] || this.searchParameterValues[i.name] === false);

      filledData.forEach((item) => {
        let value;
        if (item.type === 'text') {
          value = this.searchParameterValues[item.name];
        } else if (item.type === 'dropdown') {
          value = this.searchParameterValues[item.name];
        } else if (item.type === 'dropdown-multiselect') {
          value = this.searchParameterValues[item.name].join(',');
        }
        queryString[item.name] = value;

        if (item.name === 'companyType') {
          const companyType = this.companyTypes.find((ct) => ct.id.toString() === this.searchParameterValues[item.name]);
          if (companyType?.parent) {
            queryString[item.name] = companyType.parent.toString();
            queryString['stage'] = this.searchParameterValues[item.name];
          }
        }
      });

      return queryString;
    },
    apply() {
      this.$emit('submit', this.filterData());
      this.SET_SEARCH_FILTER_DATA(this.filterData());
    },
    clear() {
      Object.keys(this.searchParameterValues).forEach((key) => {
        this.searchParameterValues[key] = null;
      });
    },
  },
};
</script>

<style lang="scss">
.search-input {
  border: solid 1px #ccc;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.ll:before {
  content: attr(data-ll);
}
</style>
